<template>
  <!-- 查看更多 -> 图集系列 -->
  <div class="autoscroll-box">
    <div class="home-content content">
      <div class="more-title">
        <p>{{ collection_name }}</p>
        <span>为您推荐{{ total_count }}个精选素材</span>
      </div>
      <div class="more-list noscrollbar">
        <div id="coryright-hotmore" infinite-scroll-distance="150" v-infinite-scroll="load"
          infinite-scroll-disabled="disabled">
          <image-card @func="getMsgFormSon" v-for="item in dataList" :key="item.id" :imagedata="item"></image-card>
        </div>
        <div class="loading-icon" v-if="loading">
          <span class="el-icon-loading"></span>
          <span class="images-request-tip">图片加载中…</span>
        </div>
        <default-page v-if="defaultVisible" :status="defaultType"></default-page>
      </div>
    </div>
    <Footer v-cloak />
  </div>
</template>
<script>
import { Home } from "@/config/axios/api";
import DefaultPage from "@/components/default";
import Footer from "@/components/foot.vue";
import ImageCard from "@/components/imageCard";
import $ from "jquery";
import "justifiedGallery/dist/css/justifiedGallery.css";
import justifiedGallery from "justifiedGallery";
export default {
  name: "HotMore",
  components: { DefaultPage, Footer, ImageCard },
  data () {
    return {
      condition: {
        id: "",
        page: 0,
        page_size: 100,
      },
      dataList: [],
      total_count: 0, //全部数据
      loading: false, //是否正在加载
      collection_name: "",
      hotList: [],
      defaultVisible: false,
      defaultType: "", //缺省状态   nopic
      // popInfo:{
      //   show:false,
      //   id:''
      // }
    };
  },
  created () {
    this.collection_name = this.$route.query.name;
    this.condition.id = this.$route.params.id;
    this.dataList = [];
    this.condition.page = 0;
    this.load();
  },
  computed: {
    noMore () {
      return (
        this.total_count <= this.dataList.length ||
        this.condition.page >=
        Math.ceil(this.total_count / this.condition.page_size)
      );
    },
    disabled () {
      return this.loading || this.noMore;
    },
  },
  mounted () {
    if (document.querySelector(".autoscroll-box")) {
      document
        .querySelector(".autoscroll-box")
        .addEventListener("scroll", this.pageScrollFn);
    }
  },
  destroyed () {
    if (document.querySelector(".autoscroll-box")) {
      document
        .querySelector(".autoscroll-box")
        .removeEventListener("scroll", this.pageScrollFn);
    }
  },
  methods: {
    getMsgFormSon (data) {
      // this.popInfo.show = true;
      // this.popInfo.id = data;
      window.open(`/details?id=${data.editor_id}&img=true`, "_blank");
    },
    load () {
      if (this.loading && this.noMore) return;
      this.condition.page++;
      this.loading = true;
      Home.getHotDetail(this.condition).then((res) => {
        this.loading = false;
        if (res.status == 1) {
          res.data.data.forEach((item) => {
            item.is_favorite = item.is_collect;
          });
          this.dataList = this.dataList.concat(res.data.data);
          this.defaultVisible = false;
          this.addSomeImages();
          this.total_count = res.data.total;
          if (res.data.total == 0) {
            this.defaultVisible = true;
            this.defaultType = "nodata";
          }
        } else {
          this.defaultVisible = true;
          this.defaultType = "neterror";
        }
      });
    },
    addSomeImages () {
      this.$nextTick(function () {
        $("#coryright-hotmore").justifiedGallery({
          rowHeight: 256,
          maxRowHeight: 260,
          margins: 15,
          border: 0,
          waitThumbnailsLoad: false,
        });
      });
    },
    pageScrollFn () {
      let scrollTop = document.querySelector(".autoscroll-box").scrollTop;
      this.$store.commit("watchPageScroll", !!scrollTop);
    },
    scrollView () {
      window.scrollTo({
        top: document.querySelector(".more-other-title").offsetTop,
      });
    },
  },
};
</script>
<style scoped lang="scss">
.more-list {
  min-height: calc(100vh - 685px);
}

.autoscroll-box {
  height: 100vh;
  overflow-y: auto;
}

.content {
  @include wh(1200px, auto);
  overflow: hidden;
  margin-bottom: 60px;
}

.content {
  .more-title {
    margin: 45px 0;
    text-align: center;
    @include flex(center, "");
    flex-direction: column;

    p {
      font-size: 40px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #111111;
      line-height: 56px;
      margin-bottom: 4px;
    }

    span {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
    }

    div {
      margin-top: 10px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #222222;
      line-height: 22px;
      @include flex(center, center);
      cursor: pointer;

      &:hover {
        img.nohover {
          display: none;
        }

        img.hover {
          display: inline-block;
        }
      }

      img {
        margin-left: 7px;
        width: 20px;
        height: 20px;

        &.nohover {
          display: inline-block;
        }

        &.hover {
          display: none;
        }
      }
    }
  }

  .more-list {
    // max-height: 1000px;
    // overflow-y: auto;
    // div {
    //   @include wh(288px,218px);
    //   img  {
    //     @include wh(100%, 100%);
    //   }
    // }
  }

  .more-other {
    margin-bottom: 197px;

    .more-other-title {
      text-align: center;
      margin: 54px 0 32px;

      p {
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #111111;
        line-height: 33px;
        margin-bottom: 5px;
      }

      span {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
      }
    }

    .more-other-list {
      @include flex(center, space-between);

      li {
        @include wh(230px, 172px);
        border-radius: 4px;
        position: relative;
        transition: 0.3s;
        cursor: pointer;

        &:hover {
          div {
            display: block;
          }
        }

        img {
          @include wh(100%, 100%);
        }

        div {
          @include wh(100%, 100%);
          display: none;
          position: absolute;
          top: 0;
          left: 0;
          background: rgba(0, 0, 0, 0.4);
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 20px;
          padding-top: 78px;
          box-sizing: border-box;
          text-align: center;
        }
      }
    }
  }
}
</style>
